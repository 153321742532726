import { useSignupFlowFormData } from "@features/signup/SignupFlowProvider";
import { SignupStep, SignupStepPathMap } from "@features/signup/constants";
import { EventPrefix, analyticsEvent } from "@util/analytics";
import { SubscriptionTier } from "@util/constants";
import { useHistory } from "react-router-dom";
import useSignupStepsList from "./useSignupStepsList";

interface UseNavigateProps {
  currentStep: SignupStep;
}

const useNavigateSignup = ({ currentStep }: UseNavigateProps) => {
  const { formData } = useSignupFlowFormData();

  const steps = useSignupStepsList();
  const history = useHistory();
  const currentStepIndex = steps.data.indexOf(currentStep);
  // Skip going back to the Enterprise Details page if the user does not select enterprise plan
  const previousStepDecrement =
    currentStep === SignupStep.Payment && !formData.enterpriseDetails ? 2 : 1;
  const previousPath =
    SignupStepPathMap[steps.data[currentStepIndex - previousStepDecrement]];
  // Skip the Enterprise Details page if the user does not select enterprise plan
  const showEnterpriseDetailsPage =
    currentStep === SignupStep.Plan &&
    formData.plan?.planType === SubscriptionTier.ENTERPRISE;
  const nextStepIncrement =
    currentStep === SignupStep.Plan && !showEnterpriseDetailsPage ? 2 : 1;
  const nextPath =
    SignupStepPathMap[steps.data[currentStepIndex + nextStepIncrement]];

  return {
    previousPath,
    nextPath,
    goToNextStep: () => {
      analyticsEvent(
        `${EventPrefix.SignupFlowRedesign}: Completed ${currentStep} step`,
      );
      if (nextPath) {
        history.push({ ...history.location, pathname: nextPath });
      }
    },
    goToPreviousStep: () => {
      if (previousPath) {
        history.push({ ...history.location, pathname: previousPath });
      }
    },
  };
};

export default useNavigateSignup;
