import { ChainId } from "@alch/dx-entities";
import { Tooltip } from "@alch/ui";
import { HelpCircleContained } from "@alch/ui/icons/16";
import ChainEmblem from "@components/ui/icons/ChainEmblem";
import { createEmblemComponent } from "@components/ui/icons/emblem";
import SignupTemplatePage from "@features/signup/Pages/SignupTemplatePage";
import {
  SignupFormFieldName,
  SignupSchemaField,
  SignupStep,
  UnsupportedChain,
} from "@features/signup/constants";
import { useSignupForm } from "@features/signup/hooks/useSignupForm";
import { useChainInfos } from "@queries/useChain";
import AlephZeroLogo from "@static/unsupported-chain-logos/aleph-zero.svg";
import AuroraLogo from "@static/unsupported-chain-logos/aurora.svg";
import BobaNetworkLogo from "@static/unsupported-chain-logos/boba-network.svg";
import CoreBlockchainLogo from "@static/unsupported-chain-logos/core-blockchain.svg";
import ImmutableZkEvmLogo from "@static/unsupported-chain-logos/immutable-zkevm.svg";
import RskLogo from "@static/unsupported-chain-logos/rsk.svg";
import ZkSyncEraLogo from "@static/unsupported-chain-logos/zksync-era.svg";
import type { PillToggleItem } from "@ui/pill-toggle-group/PillToggleGroup.tsx";
import clsx from "clsx";
import { SelectField } from "../../../components/ui/index";
import PillToggleGroup from "../../../components/ui/pill-toggle-group/PillToggleGroup";

const CURRENT_STEP = SignupStep.Chain;

const OTHER_CHAIN_ITEMS = [
  {
    value: UnsupportedChain.ZkSyncEra,
    label: "ZKsync Era",
    iconUrl: ZkSyncEraLogo,
  },
  {
    value: UnsupportedChain.Aurora,
    label: "Aurora",
    iconUrl: AuroraLogo,
  },
  {
    value: UnsupportedChain.ImmutableZkEvm,
    label: "Immutable zkEVM",
    iconUrl: ImmutableZkEvmLogo,
  },
  {
    value: UnsupportedChain.CoreBlockchain,
    label: "Core Blockchain",
    iconUrl: CoreBlockchainLogo,
  },
  {
    value: UnsupportedChain.AlephZero,
    label: "Aleph Zero",
    iconUrl: AlephZeroLogo,
  },
  {
    value: UnsupportedChain.Rsk,
    label: "RSK",
    iconUrl: RskLogo,
  },
  {
    value: UnsupportedChain.BobaNetwork,
    label: "Boba Network",
    iconUrl: BobaNetworkLogo,
  },
].map(({ value, label, iconUrl }) => {
  const Icon = createEmblemComponent(iconUrl, label);

  return {
    value,
    label,
    Icon,
    displayLabel: (
      <span className="flex items-center gap-2">
        <Icon className="size-6" />
        {label}
      </span>
    ),
  };
});

const OTHER_CHAIN_VALUE_TO_ITEM = Object.fromEntries(
  OTHER_CHAIN_ITEMS.map((item) => [item.value, item]),
);

const renderSelectedValue = (selectedChains: UnsupportedChain[]) => {
  if (selectedChains.length === 0) return;

  const icons = selectedChains.map(
    (chain) => OTHER_CHAIN_VALUE_TO_ITEM[chain].Icon,
  );
  const firstChainName = OTHER_CHAIN_VALUE_TO_ITEM[selectedChains[0]].label;
  const numSelectedChains = selectedChains.length;

  const dropdownText =
    numSelectedChains > 1
      ? `${firstChainName} and ${numSelectedChains - 1} more`
      : firstChainName;

  return (
    <div className=" center flex items-center justify-start">
      {icons.slice(0, 3).map((Icon, index) => (
        <Icon
          key={index}
          className={clsx("size-6 rounded-2xl border border-white")}
          style={{
            zIndex: 30 - index * 10,
            marginLeft: index === 0 ? 0 : -8,
          }}
        />
      ))}
      <div className="w-2" />
      <p>{dropdownText}</p>
    </div>
  );
};

const otherChainsTooltip = (
  <div className="flex items-center">
    <p>{"Other chains you're building on"}</p>
    <div className="w-1" />
    <Tooltip
      withArrow
      content="We want to support you on all chains you are interested in building on, however, we do not yet support all of the chains on this list."
    >
      <HelpCircleContained className="text-grayscale-500" />
    </Tooltip>
  </div>
);

const ChainPage = () => {
  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useSignupForm({
    schemaField: SignupSchemaField.Chain,
    defaultValues: {
      [SignupFormFieldName.Chains]: [],
      [SignupFormFieldName.OtherChains]: [],
    },
  });

  const chainInfos = useChainInfos();

  const chainItems: PillToggleItem<ChainId>[] = (chainInfos.data ?? []).map(
    (chainInfo) => ({
      value: chainInfo.id,
      label: chainInfo.name,
      Icon: <ChainEmblem chainInfo={chainInfo} />,
    }),
  );

  return (
    <SignupTemplatePage
      title="What chains are you building on?"
      subtitle="You can always add more later!"
      currentStep={CURRENT_STEP}
      disableNextButton={!isValid}
      onNextButtonClick={handleSubmit}
      isLoading={chainInfos.isLoading}
    >
      <PillToggleGroup
        items={chainItems}
        control={control}
        name={SignupFormFieldName.Chains}
      />
      <div className="h-4" />
      <SelectField
        items={OTHER_CHAIN_ITEMS}
        label={otherChainsTooltip}
        control={control}
        name={SignupFormFieldName.OtherChains}
        multiselect
        renderSelectedValue={renderSelectedValue}
      />
    </SignupTemplatePage>
  );
};

export default ChainPage;
