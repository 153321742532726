import { CompleteSignupRequestParams } from "@features/signup/types";
import { SearchParam } from "@hooks/useSearchParam";
import { USER_KEY } from "@queries/useCurrentUser";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { completeSignup } from "../../http/endpoints";
import { ONBOARDING_STATUS_QUERY_KEY } from "../queries/useOnboardingStatus";

function useCompleteSignup() {
  const queryClient = useQueryClient();
  const location = useLocation();
  const history = useHistory();

  const handleSuccess = useCallback(() => {
    // Invalidate queries dependent on onboarding status
    void queryClient.invalidateQueries({
      queryKey: ONBOARDING_STATUS_QUERY_KEY,
    });
    void queryClient.invalidateQueries({ queryKey: USER_KEY });

    // Remove test signup param from url
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has(SearchParam.TestSignup)) {
      searchParams.delete(SearchParam.TestSignup);
      history.replace({ ...location, search: searchParams.toString() });
    }
  }, [queryClient, history, location]);

  return useMutation({
    mutationFn: (params: CompleteSignupRequestParams) => completeSignup(params),
    onSuccess: handleSuccess,
  });
}

export default useCompleteSignup;
