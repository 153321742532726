import useCheckSetupIntent from "@features/stripe/useCheckSetupIntent";
import useLoadStripe from "@features/stripe/useLoadStripe";
import { Elements } from "@stripe/react-stripe-js";
import { SignupStep } from "../constants";
import SignupTemplatePage from "./SignupTemplatePage";

const CURRENT_STEP = SignupStep.PaymentConfirmation;

const StripePaymentConfirmationPageContent = () => {
  const checkSetupIntentRes = useCheckSetupIntent();

  return (
    <SignupTemplatePage
      title="Payment Confirmation"
      currentStep={CURRENT_STEP}
      isLoading={checkSetupIntentRes.isPending}
      disableNextButton={!checkSetupIntentRes.isSuccess}
      errorMessage={checkSetupIntentRes.error?.message}
    >
      {checkSetupIntentRes.data && checkSetupIntentRes.data.message}
    </SignupTemplatePage>
  );
};

const StripePaymentConfirmationPage = () => {
  const stripeRes = useLoadStripe();

  return (
    <Elements stripe={stripeRes.data || null}>
      <StripePaymentConfirmationPageContent />
    </Elements>
  );
};

export default StripePaymentConfirmationPage;
