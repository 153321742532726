import { SearchParam } from "@hooks/useSearchParam";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeError } from "@stripe/stripe-js";
import { preserveQueryParamsInPath } from "@util/urls";
import { useState } from "react";

export default function useStripeInSignup(options: { redirectPath: string }) {
  const stripe = useStripe();
  const elements = useElements();

  const [submitting, setSubmitting] = useState(false);
  const [loadErrorMessage, setLoadErrorMessage] = useState<string | undefined>(
    undefined,
  );

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      return null;
    }

    setSubmitting(true);

    // Preserve some flags when we redirect back
    const preservedRedirectPath = preserveQueryParamsInPath(
      globalThis.location.href,
      options.redirectPath,
      [
        SearchParam.ViewAsUser,
        SearchParam.ViewAsTeam,
        SearchParam.TestSignup,
        "flag_stripe",
      ],
    );

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        // On submit, the user will always be redirected to stripe and back
        return_url: `${window.location.origin}${preservedRedirectPath}`,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment and stripe has not started a redirect.
      // Stripe will automatically show errors inline in its form, so we don't need to handle the errors
      // ourselves here.
      setSubmitting(false);
      console.error(error);
    }
  };

  const handleLoadError = (event: {
    elementType: "payment";
    error: StripeError;
  }) => {
    setLoadErrorMessage(event.error?.message);
  };

  return {
    // Stripe has finished loading
    isLoading: loadErrorMessage === undefined && (!stripe || !elements),
    // An error occurred while loading Stripe
    handleLoadError,
    loadErrorMessage,
    // Form is submitting
    handleSubmit,
    submitting,
  };
}
