import useSearchParam from "@hooks/useSearchParam";
import { useStripe } from "@stripe/react-stripe-js";
import { useQuery } from "@tanstack/react-query";

/**
 * Checks a setup_intent_client_secret that stripe has passed back to us
 * from the redirect url.  Returns a success state or error message.
 *
 * Hook must be a desecendent of <Elements />
 */
export default function useCheckSetupIntent() {
  const secret = useSearchParam("setup_intent_client_secret");
  const stripe = useStripe();

  return useQuery({
    queryKey: ["stripe-setup-intent", secret],
    queryFn: async () => {
      if (!stripe) {
        throw new Error("Stripe not initialized");
      }
      if (!secret) {
        throw new Error(
          "Stripe did not pass us the correct information to complete this request.",
        );
      }

      const { setupIntent } = await stripe.retrieveSetupIntent(secret);
      if (!setupIntent) {
        throw new Error(
          "Could not retreive information about your payment.  Please try again.",
        );
      }

      switch (setupIntent.status) {
        case "succeeded":
          return {
            setupIntentId: setupIntent.id,
            message: "Your payment was successful!",
          };

        case "processing":
          // Payment details are processing, but assume success.
          return {
            id: setupIntent.id,
            message: "Payment is processing.",
          };

        case "requires_payment_method":
          throw new Error(
            "Failed to process payment details. Please try another payment method.",
          );

        default:
          throw new Error("Something went wrong. Please try again.");
      }
    },
    enabled: stripe !== null,
  });
}
