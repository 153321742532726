import { Flag } from "@alch/dx-entities";
import { Loading } from "@alch/ui";
import { useFlag } from "@hooks/useFlag";
import ChargifyPaymentPage from "./PaymentPage/ChargifyPaymentPage";
import StripePaymentPage from "./StripePaymentPage/StripePaymentPage";

const FeatureFlaggedPaymentPage = () => {
  const flag = useFlag(Flag.Stripe);
  console.log("FeatureFlaggedPaymentPage", flag.status, flag.data);
  if (flag.isPending) return <Loading />;

  return flag.isError || !flag.data ? (
    <ChargifyPaymentPage />
  ) : (
    <StripePaymentPage />
  );
};

export default FeatureFlaggedPaymentPage;
