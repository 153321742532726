import SignupTemplatePage from "@features/signup/Pages/SignupTemplatePage";
import {
  SignupFormFieldName,
  SignupSchemaField,
  SignupStep,
} from "@features/signup/constants";
import { TextField } from "../../../components/ui/index";
import { useSignupForm } from "../hooks/useSignupForm";

const CURRENT_STEP = SignupStep.Referrer;

const ReferrerPage = () => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitSuccessful, isSubmitting },
  } = useSignupForm({
    schemaField: SignupSchemaField.Referrer,
    defaultValues: {
      [SignupFormFieldName.Referrer]: "",
    },
  });

  return (
    <SignupTemplatePage
      title="Last thing, how did you hear about us?"
      currentStep={CURRENT_STEP}
      onNextButtonClick={handleSubmit}
      formSubmitOverride={isSubmitSuccessful && !isSubmitting}
    >
      <TextField
        name={SignupFormFieldName.Referrer}
        control={control}
        label="Input your answer (optional)"
        className="w-full"
      />
    </SignupTemplatePage>
  );
};

export default ReferrerPage;
