import { SearchParam } from "@hooks/useSearchParam";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { SignupStepPathMap } from "../constants";
import useSignupStepsList from "./useSignupStepsList";

const useSignupDefaultLocation = () => {
  const location = useLocation();
  const steps = useSignupStepsList();

  return useMemo(() => {
    const { search, pathname } = location;
    const searchParams = new URLSearchParams(search);
    const hasRedirectParam = searchParams.has(SearchParam.RedirectUrl);

    if (!hasRedirectParam) {
      // Remove test signup param from redirect url
      const redirectSearchParams = new URLSearchParams(searchParams);
      redirectSearchParams.delete(SearchParam.TestSignup);

      const redirectSearch =
        redirectSearchParams.size === 0
          ? ""
          : `?${redirectSearchParams.toString()}`;

      // Set redirect url to the current path
      searchParams.set(SearchParam.RedirectUrl, `${pathname}${redirectSearch}`);
    }

    return {
      ...location,
      search: searchParams.toString(),
      pathname: SignupStepPathMap[steps.data[0]],
    };
  }, [location, steps]);
};

export default useSignupDefaultLocation;
