import { Modal } from "@alch/ui";
import { AuthError } from "@util/errors.ts";
import ErrorPage from "../../features/common/ErrorPage.tsx";
import SignupRoutes from "../../features/signup/SignupRoutes.tsx";
import useAuthRedirect from "../../hooks/useAuthRedirect.ts";
import useTrackingQueryParams from "../../hooks/useTrackingQueryParams.ts";
import useOnboardingStatus from "../../react-query/queries/useOnboardingStatus.ts";
import App from "./App.tsx";

Modal.setAppElement("#root");

const Root = () => {
  const {
    hasPlan,
    isPending: isOnboardingStatusLoading,
    error: onboardingStatusError,
  } = useOnboardingStatus();

  useAuthRedirect();
  useTrackingQueryParams();

  if (isOnboardingStatusLoading) {
    return null;
  }

  if (onboardingStatusError) {
    // AuthErrors are handled by useAuthRedirect and the user is redirected back to Authchemy
    if (onboardingStatusError instanceof AuthError) {
      return null;
    }

    return (
      <ErrorPage
        message={onboardingStatusError.message ?? "Something went wrong"}
      />
    );
  }

  if (!hasPlan) {
    return <SignupRoutes />;
  }

  return <App />;
};

export default Root;
