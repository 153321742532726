import { SignupStep, SignupStepPathMap } from "@features/signup/constants";
import { PaymentElement } from "@stripe/react-stripe-js";
import useStripeInSignup from "../../hooks/useStripeInSignup";
import SignupTemplatePage from "../SignupTemplatePage";

const CURRENT_STEP = SignupStep.Payment;

const StripePaymentPage = () => {
  const stripeForm = useStripeInSignup({
    redirectPath: SignupStepPathMap[SignupStep.PaymentConfirmation],
  });

  return (
    <SignupTemplatePage
      title="Add payment method"
      subtitle="You're almost ready to unlock the full power of web3."
      currentStep={CURRENT_STEP}
      onNextButtonClick={stripeForm.handleSubmit}
      formSubmitOverride={false} // disable automatically navigating to the next step
      isSubmitting={stripeForm.submitting}
      isLoading={stripeForm.isLoading}
      errorMessage={stripeForm.loadErrorMessage}
    >
      <PaymentElement onLoadError={stripeForm.handleLoadError} />
    </SignupTemplatePage>
  );
};

export default StripePaymentPage;
