import { ErrorView, Loading } from "@alch/ui";
import useLoadStripe from "@features/stripe/useLoadStripe";
import { Elements } from "@stripe/react-stripe-js";
import { trpc } from "@util/trpc/trpcClient";
import { useEffect } from "react";
import StripePaymentPageContent from "./StripePaymentPageContent";

const StripePaymentPage = () => {
  const stripeRes = useLoadStripe();

  const intentRes = trpc.billing.createSetupIntentForSignup.useMutation();

  // Make sure we only create one SetupIntent
  useEffect(() => {
    intentRes.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only create one setup intent
  }, []);

  // We can't render <Elements /> until we have a client secret, so this is a
  // separate loading/error screen from the subcomponents
  if (stripeRes.isError || intentRes.isError) {
    return (
      <ErrorView
        message={intentRes.error?.message || stripeRes.error?.message}
      />
    );
  }

  if (intentRes.isIdle || intentRes.isPending || stripeRes.isPending) {
    return <Loading className="mx-auto my-4" />;
  }

  return (
    <Elements
      stripe={stripeRes.data || null}
      options={{ clientSecret: intentRes.data.clientSecret }}
    >
      <StripePaymentPageContent />
    </Elements>
  );
};

export default StripePaymentPage;
