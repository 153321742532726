import { Flag } from "@alch/dx-entities";
import { useFlag } from "@hooks/useFlag";
import {
  SignupStep,
  SignupStepsList,
  SimplifiedSignupStepsList,
} from "../constants";

const useSignupStepsList = () => {
  const simplifyFlag = useFlag(Flag.SimplifyAppHomeOnboarding);
  const stripeFlag = useFlag(Flag.Stripe);

  const baseSteps = simplifyFlag.data?.is_test
    ? SimplifiedSignupStepsList
    : SignupStepsList;

  const steps = baseSteps.filter(
    (step) => stripeFlag.data || step !== SignupStep.PaymentConfirmation,
  );

  return {
    data: steps,
    isPending: simplifyFlag.isPending || stripeFlag.isPending,
  };
};

export default useSignupStepsList;
