import { loadStripe } from "@stripe/stripe-js";
import { useQuery } from "@tanstack/react-query";
import { STRIPE_PUBLISHABLE_KEY } from "@util/settings";

/**
 * Intializes Stripe.js and returns the Stripe object to be passed to <Elements />.
 *
 * Stripe's useStripe() hook can be called while this hook is still loading, but it will
 * return null.
 *
 * @example
 * () => {
 *   const stripeRes = useLoadStripe();
 *   return (
 *     <Elements stripe={stripeRes.data || null}>
 *       <ComponentThatUsesStripe />
 *     </Elements>
 *   );
 * };
 *
 */
export default function useLoadStripe() {
  return useQuery({
    queryKey: ["stripe"],
    queryFn: () => loadStripe(STRIPE_PUBLISHABLE_KEY),
    select: (data) => {
      // This will never happen outside SSR, so add this check to narrow the return type.
      if (data === null) {
        throw new Error("Stripe not initialized");
      }
      return data;
    },
  });
}
