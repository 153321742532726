import { emitDuplicatePaymentProfileErrorIfNeeded } from "@features/profile/paymentProfileUtils.ts";
import BillingInfoFormContent from "@features/signup/Pages/PaymentPage/BillingInfoFormContent";
import CreditCardFormContent from "@features/signup/Pages/PaymentPage/CreditCardFormContent";
import ExpandableForm from "@features/signup/Pages/PaymentPage/ExpandableForm";
import PaymentPromoBanner from "@features/signup/Pages/PaymentPage/PaymentPromoBanner";
import PaymentSummaryCard from "@features/signup/Pages/PaymentPage/PaymentSummaryCard";
import SignupTemplatePage from "@features/signup/Pages/SignupTemplatePage";
import { useSignupFlowFormData } from "@features/signup/SignupFlowProvider";
import {
  SignupFormFieldName,
  SignupSchemaField,
  SignupStep,
} from "@features/signup/constants";
import useChargify from "@features/signup/hooks/useChargify";
import useNavigateSignup from "@features/signup/hooks/useNavigateSignup";
import { useSignupForm } from "@features/signup/hooks/useSignupForm";
import { SubscriptionTier } from "@util/constants";
import { difference, isEmpty } from "lodash";
import { useCallback, useEffect, useState } from "react";
import useCheckCreditCard from "../../../../react-query/mutations/useCheckCreditCard";

const CURRENT_STEP = SignupStep.Payment;

const ChargifyPaymentPage = () => {
  const { formData, setFormData } = useSignupFlowFormData();
  const [isCreditCardFormOpen, setIsCreditCardFormOpen] = useState(true);
  const [isBillingFormOpen, setIsBillingFormOpen] = useState(false);
  const [chargifyTokenAdded, setChargifyTokenAdded] = useState(false);
  const { goToNextStep } = useNavigateSignup({ currentStep: CURRENT_STEP });
  const {
    mutateAsync: checkCreditCardMutation,
    error: checkCreditCardError,
    isPending: checkCreditCardIsPending,
  } = useCheckCreditCard();

  const onNewChargifyToken = useCallback(
    async (chargifyToken: string) => {
      try {
        await checkCreditCardMutation({ chargify_token: chargifyToken });
        setFormData((existingFormData) => ({
          ...existingFormData,
          ...{ [SignupSchemaField.Payment]: { chargifyToken } },
        }));
        setChargifyTokenAdded(true);
      } catch (err) {
        if (!emitDuplicatePaymentProfileErrorIfNeeded(err as Error)) {
          // Re-throw if this isn't a duplicate payment profile error
          throw err;
        }
      }
    },
    [setFormData, checkCreditCardMutation],
  );

  const {
    formRef,
    generateToken,
    error: chargifyError,
    isScriptLoaded,
    isGeneratingToken,
    isCardFilled,
  } = useChargify({ onNewChargifyToken });

  const billingSignupForm = useSignupForm({
    schemaField: SignupSchemaField.Billing,
    defaultValues: {
      [SignupFormFieldName.Address]: "",
      [SignupFormFieldName.City]: "",
      [SignupFormFieldName.Country]: "",
      [SignupFormFieldName.ZipCode]: "",
      [SignupFormFieldName.State]: "",
    },
    rehydrateValues: false,
  });

  const {
    handleSubmit,
    formState: {
      isValid,
      errors: billingFormErrors,
      isDirty,
      isSubmitSuccessful: isBillingFormSubmitSuccessful,
      submitCount,
    },
  } = billingSignupForm;

  useEffect(() => {
    if (!isEmpty(billingFormErrors)) {
      setIsBillingFormOpen(true);
    }
    if (chargifyError) {
      setIsCreditCardFormOpen(true);
    }
  }, [billingFormErrors, chargifyError]);

  useEffect(() => {
    if (isBillingFormSubmitSuccessful) {
      generateToken();
    }
  }, [generateToken, isBillingFormSubmitSuccessful, submitCount]);

  // SignupRoutes will redirect to beginning of Signup flow on refresh
  if (!formData.plan) {
    return null;
  }

  const { planType, planTerm } = formData.plan;

  const skippablePlans = [SubscriptionTier.FREE, SubscriptionTier.ENTERPRISE];

  const nonSkippablePlans = difference(
    [
      SubscriptionTier.FREE,
      SubscriptionTier.ENTERPRISE,
      SubscriptionTier.GROWTH,
      SubscriptionTier.SCALE,
    ],
    skippablePlans,
  );

  const canSkipAndShowPromoBanner = skippablePlans.includes(planType);
  const shouldSkip = canSkipAndShowPromoBanner && !isDirty && !isCardFilled;

  const shouldDisableNextButton =
    nonSkippablePlans.includes(planType) && !isValid;

  const handleNext = async () => {
    if (shouldSkip) {
      goToNextStep();
    } else {
      setChargifyTokenAdded(false);
      await handleSubmit();
    }
  };

  let errorMessage = null;
  if (chargifyError !== null) {
    errorMessage = chargifyError;
  } else if (checkCreditCardError) {
    errorMessage = checkCreditCardError.message;
  }

  return (
    <SignupTemplatePage
      title="Add payment method"
      subtitle="You're almost ready to unlock the full power of web3."
      currentStep={SignupStep.Payment}
      enableSkipButton={canSkipAndShowPromoBanner}
      onNextButtonClick={handleNext}
      disableNextButton={shouldDisableNextButton}
      ref={formRef}
      errorMessage={errorMessage}
      formSubmitOverride={shouldSkip ? undefined : chargifyTokenAdded}
      isSubmitting={isGeneratingToken || checkCreditCardIsPending}
    >
      <div className="grid w-min min-w-full grid-cols-1 gap-y-4">
        {planType !== undefined && canSkipAndShowPromoBanner && (
          <PaymentPromoBanner />
        )}
        <PaymentSummaryCard tier={planType} term={planTerm} />
        <ExpandableForm
          title="Credit card"
          isOpen={isCreditCardFormOpen}
          setIsOpen={(isOpen: boolean) => {
            // Toggle current form and close other form if it is open
            setIsCreditCardFormOpen(isOpen);
            if (isOpen) {
              setIsBillingFormOpen(!isOpen);
            }
          }}
          isInvalid={!!chargifyError}
        >
          <CreditCardFormContent isLoading={!isScriptLoaded} />
        </ExpandableForm>
        <ExpandableForm
          title="Billing info"
          isOpen={isBillingFormOpen}
          setIsOpen={(isOpen: boolean) => {
            // Toggle current form and close other form if it is open
            setIsBillingFormOpen(isOpen);
            if (isOpen) {
              setIsCreditCardFormOpen(!isOpen);
            }
          }}
          isInvalid={!isEmpty(billingFormErrors)}
        >
          <BillingInfoFormContent form={billingSignupForm} />
        </ExpandableForm>
      </div>
    </SignupTemplatePage>
  );
};

export default ChargifyPaymentPage;
