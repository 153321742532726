import { QueryErrorResetBoundary } from "@tanstack/react-query";
import { logException } from "@util/errors";
import {
  FallbackProps,
  ErrorBoundary as ReactErrorBoundary,
  ErrorBoundaryProps as ReactErrorBoundaryProps,
} from "react-error-boundary";
import { ErrorState } from "../ui";

interface ErrorBoundaryProps {
  children: React.ReactNode;
  fallback?: ReactErrorBoundaryProps["fallbackRender"];
}

function ErrorBoundary({ children, fallback }: ErrorBoundaryProps) {
  return (
    <QueryErrorResetBoundary>
      {({ reset: resetQueryError }) => (
        <ReactErrorBoundary
          fallbackRender={fallback || ErrorFallback}
          onError={logException}
          onReset={resetQueryError}
        >
          {children}
        </ReactErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}

function ErrorFallback({ resetErrorBoundary }: FallbackProps) {
  return (
    <ErrorState className="h-screen w-screen" onReload={resetErrorBoundary} />
  );
}

export default ErrorBoundary;
