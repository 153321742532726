import { ChainInfo, Product } from "@alch/dx-entities";
import { SearchParam } from "@hooks/useSearchParam.ts";

enum SettingsTab {
  AccessKeys = "access-keys",
  Profile = "profile",
  Team = "team",
}

enum Path {
  Home = "/",
  Apps = "/apps",
  CreateApp = `${Path.Apps}/new`,
  Services = "/services",
  ChatWeb3 = "/chat-web-3",
  ChatWeb3Chat = `${Path.ChatWeb3}/c`,
  Logout = "/logout",
  Logs = "/logs",
  Sandbox = "/sandbox",
  SubgraphsRedirect = "/redirect/subgraphs",
  Support = "/support",
  Mempool = "/mempool",
  Usage = "/usage",
  Webhooks = "/webhooks",
  WebhooksCreate = `${Path.Webhooks}/create`,
  AlertsHub = "/alerts",
  Chains = "/chains",
  NewUsagePage = "/_usage",

  // Chain Services
  Rollups = "/rollups",
  RollupsDeploy = "/rollups/deploy",

  // Wallet Services
  GasManager = "/gas-manager",
  GasManagerPolicy = `${Path.GasManager}/policy`,
  GasManagerPolicyCreate = `${Path.GasManagerPolicy}/create`,
  Accounts = "/accounts",
  AccountsConfigCreate = `${Path.Accounts}/config/create`,

  // Data Platform
  DataPlatform = "/pipelines",
  DataPlatformCreate = "/pipelines/create",

  // Settings
  Settings = "/settings",
  AccessKeys = `${Path.Settings}/${SettingsTab.AccessKeys}`,
  Billing = `${Path.Settings}/billing`,
  BillingSpendingCap = `${Path.Billing}#spending-cap`,
  BillingAutoScaling = `${Path.Billing}#auto-scaling`,
  PaymentDetails = `${Path.Billing}`,
  Downgrade = `${Path.Settings}/billing/downgrade`,
  Profile = `${Path.Settings}/${SettingsTab.Profile}`,
  Team = `${Path.Settings}/${SettingsTab.Team}`,
  ConfigureAlerts = `${Path.Settings}/alerts`,

  //Signup
  Signup = "/signup",
  SignupTeam = `${Path.Signup}/team`,
  SignupProject = `${Path.Signup}/project`,
}

export type AppDetailsTab = "setup" | "metrics" | "networks" | "settings";

export enum BillingPageTab {
  ManagePlan = "manage-plan",
  Invoices = "invoices",
  PaymentDetails = "payment-details",
}

export enum NewUsagePageTab {
  Overview = "overview",
  Http = "http",
  WebSockets = "websockets",
  Webhooks = "webhooks",
  Subgraphs = "subgraphs",
}

const pathBuilder = {
  appDetails: (id: string, tab?: AppDetailsTab) =>
    `${Path.Apps}/${id}${tab == null ? "" : `/${tab}`}`,
  billing: (tab: `${BillingPageTab}`) => `${Path.Billing}/${tab}`,
  chatWeb3: {
    chat: (id: string) => `${Path.ChatWeb3Chat}/${id}`,
  },
  gasManager: {
    editPolicy: (id: string) => `${Path.GasManagerPolicy}/${id}/edit`,
    reviewPolicy: (id: string) => `${Path.GasManagerPolicy}/${id}`,
  },
  accounts: {
    editConfig: (id: string) => `${Path.Accounts}/config/${id}/edit`,
  },
  alerts: {
    editAlert: (id: number) => `${Path.ConfigureAlerts}/${id}`,
  },
  chainServices: {
    rollupDetails: (raasId: string) => `${Path.Rollups}/${raasId}`,
  },
  dataPlatform: {
    pipeline: (id: string | number) => `${Path.DataPlatform}/pipeline/${id}`,
  },
  logs: {
    perApp: (appId: string) => `${Path.Logs}/${appId}`,
  },
  usage: {
    perApp: (appId: string) => `${Path.Usage}/${appId}`,
  },
  services: {
    landingPage: (product: Product) => `${Path.Services}/${product}`,
  },
  chains: {
    chainPage: (chainInfo: ChainInfo) =>
      `${Path.Chains}/${chainInfo.id.toLowerCase()}`,
  },
  webhooks: {
    createWithInitialType: (webhookType: number) =>
      `${Path.WebhooksCreate}?${SearchParam.InitialWebhookType}=${webhookType}`,
  },
  newUsagePage: (tab: `${NewUsagePageTab}`) => `${Path.NewUsagePage}/${tab}`,
};

export { Path, pathBuilder, SettingsTab };
